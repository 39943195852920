import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useLocation} from '@reach/router';
import {Link} from 'gatsby';

import Page from 'src/components/functional/page';
import {Col, Row} from 'react-bootstrap';
import 'styles/people.scss';
import FeaturedHeroSection
  from 'src/components/functional/hero-section-featured';
import {usePageContent} from 'src/functionality/content-provider';
import {Container} from 'react-bootstrap';
import NewsAndAnnouncements
  from 'src/components/functional/news-announcements-section';
import OurPhilosophySection
  from 'src/components/functional/our-philosophy-section';
import Spacer from 'src/components/graphical/spacer';
import styled from 'styled-components';
import ContentBlock from 'src/components/functional/content-block';
import {H3, H4, H6} from 'src/components/functional/headings';
import Tile from 'src/components/functional/tile';
import {theme} from 'src/styles/theme';
import ArbiasButton from 'src/components/functional/arbias-button';
import {breakpoints} from 'src/styles/breakpoints';
import {getResource} from 'src/functionality/resources-provider';
import {useFeatureFlags} from 'src/functionality/flags-provider';
import heroBg from 'images/about/people.jpg';
import ExecutiveWorkshop from 'images/about/people/exec-workshop.png';
import WorkingForArbiasImage from 'images/about/people/working-for-arbias.jpg';

const AboutOurClientsSection = styled.section``;
const WorkingAtArbiasSection = styled.section``;
const EmployeeSatisfactionSection = styled.section``;
const QuoteSection = styled.section``;

const CenteredDocument = styled.div`
  & > div {
    width: 100%;
  }
`;

const Quote = styled.blockquote`
  border: 0;
  text-align: center;
  color: ${theme.primary};
  font-style: italic;
`;
const TabbedSection = styled.section`
  & .col:first-of-type > div {
    border-right: 1px solid ${theme.mediumGray};
  }
  & .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media ${breakpoints.md} {
    & .col:first-of-type > div {
      border: 0;
    }
    & .col:last-of-type {
      border-bottom: 1px solid ${theme.mediumGray};
    }
    & .col {
      border-top: 1px solid ${theme.mediumGray};
    }
    text-align: center;
  }
`;
const TabButton = styled.button`
  padding: 0.25em 0;
  color: ${(props) => (props.selected ? theme.primary : theme.muted)};
`;

const Img = styled.img`
  max-height: 40vh;
`;

const PeoplePage = ({pageContext}) => {
  const [selectedTab, setSelectedTab] = useState('governance');
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);

  const flags = useFeatureFlags();
  return (
    <Page white="true">
      <FeaturedHeroSection
        breadcrumbs="true"
        bgImage={heroBg}
        content={content}
        tag="P01"
      />
      <Container>
        <Spacer height={8} />
        <AboutOurClientsSection>
          <ContentBlock
            header={(props) => <H3 {...props} />}
            content={content}
            tag="P07"
          />
          <Spacer height={2} />
          {flags.AboutPeopleClients_EnableClientStories && (
            <>
              <Row className="align-items-center">
                {[0, 0, 0, 0].map((person, key) => (
                  <Tile
                    key={key}
                    type="picture"
                    src={WorkingForArbiasImage}
                    title="Client Name"
                    subtitle="Subtitle?"
                  >
                    TODO: Description
                  </Tile>
                ))}
              </Row>
              <Link to="TODO:" className="float-end">
                More from our clients &gt;
              </Link>
            </>
          )}
        </AboutOurClientsSection>
        <Spacer height={8} />
        <WorkingAtArbiasSection>
          <Row className="align-items-center">
            <Col md={12} lg={6}>
              <Spacer height={2} />
              <ContentBlock
                content={content}
                tag="P02"
                header={H3}
                link={(props) => (
                  <ArbiasButton
                    to="/about/people/working-at-arbias/"
                    {...props}
                  />
                )}
                linkText="Life at arbias >"
              />
              <Spacer height={2} />
            </Col>
            <Col md={12} lg={6}>
              <Img src={ExecutiveWorkshop} alt="working at arbias" />
            </Col>
          </Row>
        </WorkingAtArbiasSection>
        <Spacer height={8} />
        <EmployeeSatisfactionSection>
          <Row className="align-items-center">
            <Col md={12} lg={6} xl={8}>
              <ContentBlock
                header={(props) => <H3 {...props} />}
                content={content}
                tag="P08"
              />
            </Col>
            <Col md={12} lg={6} xl={4}>
              <CenteredDocument>
                <Tile
                  isRounded
                  type="document"
                  to="/about/people/employee-satisfaction-survey/"
                  title="Employee Satisfaction Survey"
                />
                <Tile
                  isRounded
                  type="document"
                  onClick={() =>
                    // eslint-disable-next-line max-len
                    getResource(
                        'resources/Company-Reports/arbias-RIPL-Resident-Satisfaction-Research-.pdf',
                    ).then((url) => window.open(url))
                  }
                  title="RIPL Resident Satisfaction Research"
                />
              </CenteredDocument>
            </Col>
          </Row>
        </EmployeeSatisfactionSection>
        <Spacer height={8} />
        <TabbedSection>
          <H3>arbias&apos; management and advisors</H3>
          <Spacer height={2} />
          <Row>
            <Col md={12} lg={4} className="col">
              <div>
                <H6 noUnderline="true" display={6}>
                  <TabButton
                    className="plain-button"
                    selected={selectedTab == 'governance'}
                    onClick={() => setSelectedTab('governance')}
                  >
                    The Board
                  </TabButton>
                </H6>
                <H6 noUnderline="true" display={6}>
                  <TabButton
                    className="plain-button"
                    selected={selectedTab == 'managers'}
                    onClick={() => setSelectedTab('managers')}
                  >
                    Executive Managers
                  </TabButton>
                </H6>
                <H6 noUnderline="true" display={6}>
                  <TabButton
                    className="plain-button"
                    selected={selectedTab == 'friends'}
                    onClick={() => setSelectedTab('friends')}
                  >
                    Lifetime Friends
                  </TabButton>
                </H6>
              </div>
            </Col>
            <Col md={12} lg={8} className="col">
              <Spacer height={2} />
              <ContentBlock
                header={(props) => <H4 noUnderline="true" {...props} />}
                content={content}
                tag={(() => {
                  switch (selectedTab) {
                    case 'governance':
                      return 'P04';
                    case 'managers':
                      return 'P05';
                    case 'friends':
                      return 'P06';
                    default:
                      return '';
                  }
                })()}
                link={(props) => (
                  <ArbiasButton
                    to={(() => {
                      switch (selectedTab) {
                        case 'governance':
                          return '/about/people/management-and-advisors#governance';
                        case 'managers':
                          // eslint-disable-next-line max-len
                          return '/about/people/management-and-advisors#executive-managers';
                        case 'friends':
                          // eslint-disable-next-line max-len
                          return '/about/people/management-and-advisors#lifetime-friends';
                      }
                    })()}
                    {...props}
                  />
                )}
                linkText={(() => {
                  switch (selectedTab) {
                    case 'governance':
                      return 'The Board >';
                    case 'managers':
                      return 'Executive Managers >';
                    case 'friends':
                      return 'Lifetime Friends >';
                  }
                })()}
              />
              <Spacer height={2} />
            </Col>
          </Row>
        </TabbedSection>
        <Spacer height={8} />
        {flags.AboutPeopleClients_EnableCEOQuote && (
          <QuoteSection>
            <ContentBlock
              header={(props) => <H3 noUnderline />}
              content={content}
              tag="P03"
              contentWrapper={(props) => <Quote className="lead" {...props} />}
            />
            <div className="text-center lead">- John Eyre (CEO)</div>
          </QuoteSection>
        )}
        <Spacer height={8} />
        <NewsAndAnnouncements />
        <Spacer height={8} />
      </Container>
      <OurPhilosophySection />
    </Page>
  );
};

PeoplePage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.any,
  }),
};

export default PeoplePage;
